/*------------------------------------*\
		Buttons
\*------------------------------------*/

.image,
.image__wrapper {
  padding-right: $line-height * 1em;
  padding-left: $line-height * 1em;
}

.image__row {
  margin-right: -$line-height * 1em;
  margin-left: -$line-height * 1em;
}

.image {
  text-align: center;

  &,
  img {
    display: block;
  }

  img {
    width: 100%;
    height: auto;
    margin: auto;

    &.is-loaded {
      width: auto;
      max-width: 100%;
    }
  }
}

.image__img {
  .is-loaded & {
    display: inline-block;
  }
}

.image__link {
  display: block;
  background: $orange-light;

  &::before {
    $size: $line-height * 1rem;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: $size;
    height: $size;
    margin: $size * -0.5 0 0 $size * -0.5;
    background-image: url('#{$svg-dir}loader.svg');
    animation: rotate 1s $in-out-sine infinite;
  }
}

.image__caption {
  margin: 0.5em 0 0;
  font-size: 0.75em;
  opacity: 0.6;
}

.image--no-padding {
  padding-right: 0;
  padding-left: 0;
}

;@import "sass-embedded-legacy-load-done:71";