/*------------------------------------*\
    Audio List
\*------------------------------------*/

.audio-list {
  max-width: 24rem;
  margin: $line-height * 1.4em auto;
  // padding: $line-height / 1.5 * 1em;
  padding: 0;
  list-style: none;
  // background: #f9f9f9;
  // border-radius: 5px;
}

.audio-list__item {
  margin: 0;
  padding: 0.4em 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

;@import "sass-embedded-legacy-load-done:83";