/*------------------------------------*\
		Footer
\*------------------------------------*/

.footer {
  margin-top: 6rem;
  overflow: hidden;
  text-align: center;
}

.footer__inner {
  max-width: $container-max-width;
  margin: auto;
  font-size: 0.75em;
  color: $grey-medium;

  a {
    &:hover {
      color: $grey-dark;
    }
  }
}

.nav--footer {
  text-align: center;

  li {
    display: inline-block;

    + li {
      &:before {
        content: ' — ';
        display: inline-block;
      }
    }
  }

  a {
    display: inline-block;
    padding: 0 0.25em;
  }

  + p {
    margin-top: 0.5em;
  }
}

;@import "sass-embedded-legacy-load-done:37";