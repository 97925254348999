@use 'sass:list';

/*---------------------------------------*\
    Positions & dispositions
\*---------------------------------------*/

@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

/*---------------------------------------*\
    Typographie
\*---------------------------------------*/

@mixin fontfaces($webfonts, $dir) {
  @each $webfont in $webfonts {
    @font-face {
      font-family: list.nth($webfont, 1);
      font-style: #{list.nth($webfont, 4)};
      font-weight: #{list.nth($webfont, 3)};
      src:
        url('#{$dir}#{list.nth($webfont, 2)}.woff2') format('woff2'),
        url('#{$dir}#{list.nth($webfont, 2)}.woff') format('woff'),
        url('#{$dir}#{list.nth($webfont, 2)}.ttf') format('truetype');
    }
  }
}

@mixin reset-ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: antialiased;
}

// Nicely hyphenate long words
// cf. https://justmarkup.com/log/2015/07/31/dealing-with-long-words-in-css/
@mixin hyphenate {
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/*---------------------------------------*\
    Triangles
\*---------------------------------------*/

@mixin triangle-up($triangle-up-width, $triangle-up-height, $triangle-up-color) {
  width: 0;
  height: 0;
  border-color: transparent transparent $triangle-up-color;
  border-style: solid;
  border-width: 0 ($triangle-up-width * 0.5) $triangle-up-height;
}

@mixin triangle-right($triangle-right-width, $triangle-right-height, $triangle-right-color) {
  width: 0;
  height: 0;
  border-color: transparent transparent transparent $triangle-right-color;
  border-style: solid;
  border-width: ($triangle-right-height * 0.5) 0 ($triangle-right-height * 0.5)
    $triangle-right-width;
}

@mixin triangle-down($triangle-down-width, $triangle-down-height, $triangle-down-color) {
  width: 0;
  height: 0;
  border-color: $triangle-down-color transparent transparent;
  border-style: solid;
  border-width: $triangle-down-height ($triangle-down-width * 0.5) 0;
}

@mixin triangle-left($triangle-left-width, $triangle-left-height, $triangle-left-color) {
  width: 0;
  height: 0;
  border-color: transparent $triangle-left-color transparent transparent;
  border-style: solid;
  border-width: ($triangle-left-height * 0.5) $triangle-left-widt ($triangle-left-height * 0.5) 0;
}

/*---------------------------------------*\
    SVG Responsive
\*---------------------------------------*/

/*
Utilitaire pour le padding hack qui permet d'avoir des fichiers `*.svg` responsive.
Plus d'infos ici : http://tympanus.net/codrops/2014/08/19/making-svgs-responsive-with-css/
*/

@mixin padding-hack($svg-width, $svg-height, $container-width) {
  width: $container-width;
  height: 0;
  padding-top: strip-units(($svg-height / $svg-width) * $container-width) * 1%;
}

/*------------------------------------*\
    Gradients
\*------------------------------------*/

/**
 * Mixin printing a linear-gradient
 * as well as a plain color fallback
 * and the `-webkit-` prefixed declaration
 * @param {String | List | Angle} $direction - Linear gradient direction
 * @param {Arglist} $color-stops - List of color-stops composing the gradient
 */
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: list.nth(list.nth($color-stops, 1), 1);
  background: linear-gradient($direction, $color-stops);
}

;@import "sass-embedded-legacy-load-done:3";