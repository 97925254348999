/*------------------------------------*\
		Content
\*------------------------------------*/

.content__title {
  margin: 1.414em 0 0.5em;
  font-size: 1.777em;
  text-align: center;

  &:after,
  &:before {
    content: '—';
    display: block;
    font-weight: 300;
    opacity: 0.3;
  }
}

;@import "sass-embedded-legacy-load-done:25";