/*------------------------------------*\
		Pictogrammes
\*------------------------------------*/

.icon {
  display: inline-block;
  vertical-align: middle;
  user-select: none;
}

.icon--block {
  display: block;
}

// Colors
.icon--orange {
  color: $orange;
}

.icon--white {
  color: #fff;
}

.icon--green {
  color: $green;
}

.svg {
  display: block;
}

/*------------------------------------*\
    Arrows
\*------------------------------------*/

.icon--arrow-bot,
.icon--arrow-top {
  &,
  svg {
    width: 2em;
    height: 2em;
  }

  svg {
    overflow: visible;
  }

  use {
    &:nth-child(1) {
      color: $green;
      opacity: 0.5;
    }

    &:nth-child(2) {
      color: #fff;
      transform: translate(-2px, -2px);
    }

    &:nth-child(3) {
      color: tint($orange, 80%);
      transform: translate(-3px, -3px);
      transition: color 0.6s $out-expo;

      a:hover > & {
        color: $orange;
      }

      a:active > & {
        transform: translate(-2.5px, -2.5px);
      }
    }
  }
}

/*------------------------------------*\
    Relations
\*------------------------------------*/

.icon--relation {
  display: block;

  &,
  svg {
    width: 2em;
    height: 2em;
  }

  .icon__bg {
    fill: #fff;
  }
}

.icon--image-son {
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba($grey-dark, 0.2);
}

;@import "sass-embedded-legacy-load-done:51";