/*------------------------------------*\
		Relations
\*------------------------------------*/

$relation-padding: $line-height * 0.5em;

.relation {
  padding: $relation-padding 0;
  opacity: 0.75;
  transform: translateZ(0);
  transition: opacity 0.4s $out-expo;

  &.relation--has-link:first-child {
    margin-top: -$relation-padding;
  }

  &:hover,
  &.is-active {
    opacity: 1;

    .relation__title a {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.relation__figure {
  width: 2em;
  height: 2em;
  overflow: hidden;
  border-radius: 50%;

  a,
  img,
  svg {
    display: block;
  }

  a {
    width: 100%;
    height: 100%;
  }

  // Media queries
  @media #{md("medium")} {
    z-index: z('default');
    position: absolute;
    top: 50%;
    left: 0;
    margin: -1em 0 0;
  }
}

.relation__title {
  margin: 0;
  padding-left: 4em;
  font-family: $font-sans;
  font-size: 0.6em;
  font-weight: 900;
  line-height: 1.2;
  text-transform: uppercase;

  // Media queries
  @media #{md("medium", "max")} {
    z-index: z('default');
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -($line-height * 0.4em + 0.5em);
    padding: 0;
    font-size: 0.6em;
    line-height: 1;
    white-space: nowrap;

    a {
      display: block;
      margin-right: 12px;
      padding: $line-height * 0.4em;
      color: #fff;
      text-shadow: 1px 1px 0 $orange-dark;
      background: $orange;
      border-radius: 3px;

      opacity: 0;
      transform: scale(0.75);
      transform-origin: right center;
      will-change: opacity, transform;
      transition: all 0.4s $out-expo;

      &:before {
        @include triangle-right(6px, 12px, $orange);
        content: '';
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -6px;
      }
    }
  }
}

.relation__thumbnail {
  margin: 0;
}

/*------------------------------------*\
		Types de relations
\*------------------------------------*/

.relation--personnage {
  // .relation__figure {
  // 	border-radius: 50%;
  // 	overflow: hidden;
  // }
}

.relation--terme {
  // .relation__figure {
  // 	text-align: center;

  // 	b {
  // 		top: -0.16em;
  // 		font-size: 1.5em;
  // 	}
  // }
}

/*------------------------------------*\
    Placement de la relation
\*------------------------------------*/

.relation--in-image,
.relation--in-image-son {
  margin: $line-height * 0.5em;
  // margin-bottom: 0;
  padding: 0;

  &:first-child {
    margin-top: $line-height * 0.5em;
  }

  a {
    border: 0 !important;
  }

  .relation__title {
    z-index: z('default');
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -($line-height * 0.4em + 0.5em);
    padding: 0;
    font-size: 0.9em;
    line-height: 1;
    white-space: nowrap;

    a {
      display: block;
      margin-right: 12px;
      padding: $line-height * 0.4em;
      color: #fff;
      text-shadow: 1px 1px 0 $orange-dark;
      background: $orange;
      border-radius: 3px;

      opacity: 0;
      transform: scale(0.75);
      transform-origin: right center;
      will-change: opacity, transform;
      transition: all 0.4s $out-expo;

      &:before {
        @include triangle-right(6px, 12px, $orange);
        content: '';
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -6px;
      }
    }
  }

  .relation__figure {
    position: relative;
    top: auto;
    left: auto;
    margin: auto;
    overflow: visible;

    &:hover {
      + .relation__title a {
        opacity: 1;
        transform: scale(1);
      }
    }

    a[href*='http']:not([href*='alloayiti']):after {
      display: none;
    }
  }
}

;@import "sass-embedded-legacy-load-done:64";