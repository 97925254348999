/*------------------------------------*\
		Page d'accueil
\*------------------------------------*/

.article__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;

  background: no-repeat 50% 50%;
  background-size: cover;

  *:last-child {
    margin-bottom: 0;
  }

  // > *:not(:first-child) {
  // 	z-index: z("default");

  // 	&:before {
  // 		content: "";
  // 		z-index: z("default", -2);
  // 		position: absolute;
  // 		top: -1em;
  // 		left: -1em;
  // 		width: calc(100% + 2em);
  // 		height: calc(100% + 2em);
  // 		background: url("#{$svg-dir}bar.svg") no-repeat 50% 50%;
  // 		// background-size: 100% 100%;
  // 		mix-blend-mode: hue;
  // 		background: #222120;
  // 		background: orange;
  // 		border-radius: 50%;
  // 		opacity: 0.25;
  // 		filter: blur(50px);
  // 		transform: translateZ(0);
  // 	}
  // }

  // &:after,
  // &:before {
  // 	content: "—";
  // 	display: block;
  // 	font-family: $font-sans;
  // 	font-size: 1.777em;
  // 	font-weight: 300;
  // 	opacity: 0.3;
  // }
}

.article__thumbnail {
  height: calc(100vh - #{$line-height * 2rem});
  margin: $line-height * 1rem 0;

  background: no-repeat 50% 50%;
  background-size: cover;
}

.article__title {
  z-index: z('default');
  margin: 0;
  font-size: 1.777em;
  text-align: center;
  // color: $grey-dark;
}

.article__intro {
  max-width: 34em;
  margin-right: auto;
  margin-left: auto;

  font-size: 1.125em;

  + p {
    margin-top: 0;
  }
}

.article__type {
  font-family: $font-sans;
  font-size: 0.75em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

/*------------------------------------*\
    Modifier
\*------------------------------------*/

.article--making-of {
  img {
    width: 100%;
    height: auto;

    &.is-loaded {
      width: auto;
      max-width: 100%;
    }
  }

  .section__content {
    p {
      @include clearfix;
    }
  }
}

.article--livres,
.article--termes,
.article--personnages {
  max-width: $container-max-width;
  margin-right: auto;
  margin-left: auto;
}

.article--panel {
  .article__type {
    margin-top: 0;
  }

  .article__title {
    margin: 1em auto;
    font-size: 2em;
    color: $orange;
    text-shadow:
      1px 1px 0 white,
      3px 3px 0 rgba($green, 0.5);
  }

  .article__intro {
    font-size: 1em;
    text-align: center;
  }
}

;@import "sass-embedded-legacy-load-done:99";