/*------------------------------------*\
		Buttons
\*------------------------------------*/

.tease {
  margin-bottom: $line-height * 2em;
  text-align: center;
}

.tease__figure {
  margin: 0 auto $line-height * 1em;

  a,
  img {
    display: block;
  }

  img {
    width: 100%;
    height: auto;
    transform: scale(1);
    will-change: transform;
    transition: transform 1s $out-expo;
  }

  a {
    overflow: hidden;
    background: $orange-light;

    &:hover {
      // &:after {
      // 	opacity: 1;
      // 	transform: translate(-50%, -50%) scale(1);
      // }

      img {
        transform: scale(1.02);
      }
    }

    // &:after {
    // 	content: "";
    // 	position: absolute;
    // 	top: 50%;
    // 	left: 50%;
    // 	width: 14%;
    // 	height: 12%;
    // 	background: url("#{$svg-dir}eye.svg") no-repeat 50% 50%;
    // 	background-size: 100% 100%;

    // 	opacity: 0;
    // 	transform: translate(-50%, -50%) scale(0.8);
    // 	transition: opacity 0.4s $out-expo, transform 1s $out-expo;
    // 	// will-change: transform;
    // }
  }
}

.tease__title {
  margin-top: 0;
  font-size: 1.3em;
  line-height: 1.2;

  a {
    display: inline-block;
    color: $orange;
    text-shadow:
      1px 1px 0 #fff,
      2px 2px 0 rgba($green, 0.5);
    transition: color 0.4s $out-expo;

    &:hover {
      color: shade($orange, 95%);
      text-shadow:
        1px 1px 0 #fff,
        2px 2px 0 rgba($green, 0.65);
      // color: $orange-light;
      // text-shadow: 1px 1px 0 #fff, 2px 2px 0 rgba($orange, 0.75);
    }

    &:active {
      text-shadow:
        0 0 0 #fff,
        1px 1px 0 rgba($green, 0.5);
      transform: translate(2px, 2px);
    }
  }
}

.tease__intro {
  margin-top: 0;
}

.tease__link {
  font-family: $font-sans;
  font-size: 0.75em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  a {
    display: inline-block;
    margin: 0 1.4em;
    cursor: pointer;
    border-bottom: 2px solid $orange-light;
    transition: border 0.6s $out-expo;

    &:hover {
      border-color: $orange;

      span {
        transform: scale(-1) rotate(-45deg) translate(-3px, -3px);
      }
    }

    &:active {
      span {
        transform: scale(-1) rotate(-45deg) translate(-5px, -5px);
      }
    }

    span {
      position: absolute;
      top: 50%;
      left: 100%;
      display: inline-block;
      margin: $line-height * -0.5em 0 0 0.25em;
      color: $orange;
      transform: scale(-1) rotate(-45deg);
      transition: transform 0.6s $out-expo;
    }
  }
}

/*------------------------------------*\
		Variantes
\*------------------------------------*/

.tease--large {
  // Media queries
  @media #{md("medium")} {
    .tease__title {
      font-size: 1.7em;
      text-shadow:
        1px 1px 0 white,
        3px 3px 0 rgba($green, 0.5);
    }
  }
}

.tease--liens,
.tease--livres,
.tease--termes,
.tease--personnages {
  display: inline-block;
  margin: 0;

  .tease__title {
    padding: 0 $line-height * 0.5em;
    font-size: 1.7em;
    color: inherit;
    text-shadow: none;

    a {
      color: $grey-dark;
      text-shadow: none;
      border-bottom: 2px solid $orange-light;
      transition: border 0.6s $out-expo;

      &:hover {
        border-color: $orange;
      }
    }
  }
}

.tease--livres,
.tease--personnages {
  .tease__figure {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    transform: translateZ(0);
  }
}

.tease--no-margin {
  margin: 0;

  .tease__link {
    margin-bottom: 0;
  }
}

;@import "sass-embedded-legacy-load-done:57";