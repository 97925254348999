/*------------------------------------*\
		Navigation
\*------------------------------------*/

.content-nav {
  position: fixed;
  top: 0;
  left: $line-height * 1rem;
  height: 100%;
}

.content-nav__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0;

  list-style: none;
}

.content-nav__item {
  &[id] {
    display: none;
  }
}

.content-nav__link {
  display: block;
  font-family: $font-sans;
  font-size: 0.75em;
  font-weight: 900;
  text-transform: uppercase;
}

;@import "sass-embedded-legacy-load-done:89";