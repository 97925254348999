/*------------------------------------*\
		Big image
\*------------------------------------*/

.big-image {
  height: calc(100vh - #{$line-height * 2rem});
  margin: $line-height * 1rem 0;
  background: $orange-light;

  &::before {
    $size: $line-height * 1rem;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: $size;
    height: $size;
    margin: $size * -0.5 0 0 $size * -0.5;
    background-image: url('#{$svg-dir}loader.svg');
    animation: rotate 1s $in-out-sine infinite;
  }
}

.big-image__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: no-repeat 50% 50%;
  background-size: cover;
}

// Audio
.big-image__audio {
  z-index: z('default');
  position: absolute;
  bottom: $line-height * 1em;
  left: $line-height * 1em;
  cursor: default;
}

;@import "sass-embedded-legacy-load-done:73";