/*------------------------------------*\
		Cross reading section
\*------------------------------------*/

.cross-reading {
  margin: $line-height * 2rem $line-height * -1rem 0;
  // padding: $line-height * 1rem 0 0;
  background: $grey-lightest;
}

.cross-reading__inner {
  max-width: $container-max-width + $relation-max-width--smallest + $line-height;
  margin: auto;

  // Media queries
  @media #{md("medium")} {
    max-width: $container-max-width + $relation-max-width--medium + $line-height;
  }
}

.cross-reading__title {
  margin: $line-height * 1em auto;
  font-size: 1.333em;
  text-align: center;
}

.cross-reading__item {
  margin: $line-height * 2rem 0;

  &:first-child:not(:last-child) {
    margin-bottom: 0;
  }

  // Media queries
  @media #{md('medium')} {
    margin: $line-height * 2rem 0;
  }
}

;@import "sass-embedded-legacy-load-done:98";