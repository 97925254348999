/*------------------------------------*\
		Relations
\*------------------------------------*/

.section {
  max-width: $container-max-width + $relation-max-width--smallest + $line-height;
  margin: auto;
  padding-right: $relation-max-width--smallest + $line-height;

  // Media queries
  @media #{md("medium")} {
    max-width: $container-max-width + $relation-max-width--medium + $line-height;
    padding-right: $relation-max-width--medium + $line-height;
  }
}

.section__content {
  margin: auto;

  a {
    border-bottom: 2px solid $orange-light;
    transition: border 0.6s $out-expo;

    &:hover {
      border-color: $orange;
    }
  }
}

.section__relation {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: $relation-max-width--smallest;
  font-size: 0.875em;

  > h4 {
    margin-top: 0;
  }

  // Media queries
  @media #{md("medium")} {
    max-width: $relation-max-width--medium;
  }
}

/*------------------------------------*\
		Type de section
\*------------------------------------*/

.section--in-page {
  max-width: $container-max-width;
}

.section--image,
.section--in-page,
.section--image-son {
  padding-right: 0;
}

.section--image,
.section--image-son {
  max-width: none;

  .section__relation {
    position: absolute;
    top: auto;
    right: $line-height * 0.5em;
    right: auto;
    bottom: $line-height * 0.5em;
    bottom: 0;
    left: 100%;
    width: auto;
    max-width: none;
    font-size: 0.6em;
  }
}

.section--colonnes {
  max-width: none;
}

.section--making-of {
  &.section--image {
    max-width: $container-max-width * 0.75;
  }
}

.section--titre {
  &:first-child {
    h2 {
      margin-top: $line-height * 0.5em;
    }
  }

  h2 {
    margin: $line-height * 2em auto $line-height * 1em;
    color: $orange;
    text-align: center;
    text-shadow:
      1px 1px 0 white,
      3px 3px 0 rgba($green, 0.5);
  }
}

;@import "sass-embedded-legacy-load-done:67";