/*------------------------------------*\
		Header
\*------------------------------------*/

.header {
  max-width: 60em;
  margin: 0 auto;
  padding-bottom: $line-height * 1rem;
  text-align: center;
}

.header__logo {
  max-width: 30rem;
  margin: $line-height * 1rem auto;
  font-family: $font-sans;
  font-size: 2em;
  font-weight: 900;
  line-height: 1;
  text-transform: none;

  a {
    &:before {
      display: none;
    }
  }

  img {
    width: 100%;
    height: auto;

    &.is-loaded {
      width: auto;
      max-width: 100%;
    }
  }
}

.header__logo--small {
  max-width: 12rem;
}

.header__surtitle {
  margin: 1em auto 0;
  font-family: $font-sans;
  font-size: 0.5em;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  + .header__title {
    margin-top: 0.5em;
  }

  // Media queries
  @media #{md("medium")} {
    font-size: 0.875em;
  }
}

.header__title {
  max-width: 12em;
  margin: 1em auto 0.5em;
  font-size: 2em;
  color: $orange;
  text-shadow:
    1px 1px 0 white,
    3px 3px 0 rgba($green, 0.5);

  // Media queries
  @media #{md("medium")} {
    font-size: 3.157em;
  }
}

.header__intro {
  max-width: $container-max-width;
  margin-right: auto;
  margin-left: auto;

  // Media queries
  @media #{md("medium")} {
    font-size: 1.1em;
  }
}

.header__anchor {
  a {
    display: inline-block;

    &:before {
      display: none;
    }
  }
}

/*------------------------------------*\
    Nav
\*------------------------------------*/

.nav {
  margin: 0 auto 0;

  ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
  }
}

.nav--main {
  $link-margin: 0.75rem;
  font-family: $font-sans;

  font-size: 0.7em;
  font-weight: 900;
  line-height: 2;
  text-align: center;
  text-transform: uppercase;

  ul {
    display: inline-block;
    margin-bottom: -0.5em;
    padding: $line-height * 1rem 0;
  }

  li {
    float: left;
  }

  a {
    display: block;
    margin: 0 ($link-margin * 0.5);
    color: $grey-light;

    &:hover {
      color: shade($green, 90%);
      color: $grey-dark;
    }

    &:before {
      top: 80%;
    }
  }

  @media #{md("smaller")} {
    font-size: 0.75em;

    a {
      margin: 0 $link-margin;
    }
  }

  // Supports
  @supports (display: flex) {
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
}

.menu__item--current {
  a {
    color: inherit;
    border-bottom: 2px solid $orange-light;
    transition: border 0.6s $out-expo;

    &:hover {
      border-color: $orange;
    }
  }
}

;@import "sass-embedded-legacy-load-done:15";