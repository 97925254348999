/*------------------------------------*\
    Audio component
\*------------------------------------*/

.audio {
  // z-index: z("default");
  // position: absolute;
  // right: 1rem;
  // bottom: 1rem;
  // padding: 1rem;
}

.audio__title {
  margin: 0;
  font-family: $font-sans;
  font-size: 0.75em;
  font-weight: 900;

  -webkit-font-smoothing: subpixel-antialiasing;
}

.audio__progress {
  width: 100%;
  height: 0.5em;
  margin: 0.6em 0;
  overflow: hidden;
  cursor: pointer;
}

.audio__total,
.audio__current {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.audio__current {
  // z-index: z("default");
  stroke: #1ac2a1;
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
}

.audio__total {
  left: 0;
  fill: rgba(#7fecd6, 0.5);
  // fill: none;
  // stroke: darken(#1AC2A1, 10%);
  // stroke-width: 2;
}

.audio__controls {
  padding-left: 1.7em;
  overflow: hidden;
}

.audio__btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.7em;
  height: 1.7em;
  overflow: hidden;

  background: no-repeat 50% 50%;
  background-size: 1em 1em;

  transition: all 0.4s $out-expo;

  &[disabled] {
    opacity: 0.25;
  }
}

.audio__btn--play {
  background-image: url('#{$svg-dir}play.svg');

  &[disabled] {
    display: none;
  }
}

.audio__btn--pause {
  background-image: url('#{$svg-dir}pause.svg');

  &[disabled] {
    display: none;
  }
}

.audio__btn--stop {
  left: 1.7em;
  display: none;
  background-image: url('#{$svg-dir}stop.svg');
}

.audio__btn__label {
  position: absolute;
  left: -999px;
  opacity: 0;
}

;@import "sass-embedded-legacy-load-done:77";