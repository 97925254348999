/*------------------------------------*\
    Iframe Component
\*------------------------------------*/

.iframe {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.iframe__cover,
.iframe__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.iframe__cover {
  z-index: z('default');
  background: no-repeat 50% 50%;
  background-size: cover;
}

;@import "sass-embedded-legacy-load-done:74";