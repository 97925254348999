// Include fontface
// @include fontfaces($fontfaces, $font-dir);

/*---------------------------------------*\
		Sélection
\*---------------------------------------*/

::-moz-selection {
  color: $selection-color;
  text-shadow: none;
  background: $selection-bg;
}

::selection {
  color: $selection-color;
  text-shadow: none;
  background: $selection-bg;
}

/*---------------------------------------*\
		Réglages globaux
\*---------------------------------------*/

*,
*:after,
*:before {
  position: relative;
  box-sizing: inherit;
  outline: none;
}

html {
  box-sizing: border-box;
  font-size: $font-size;
  background: $orange-light;

  // Better font rendering for mac
  &.is-mac {
    @include antialiased;
  }

  // Media queries
  @media #{md("small")} {
    font-size: $font-size + 1;
  }

  @media #{md("medium")} {
    font-size: $font-size + 2;
  }

  @media #{md("normal")} {
    font-size: $font-size + 3;
  }

  @media #{md("large")} {
    font-size: $font-size + 4;
  }

  @media #{md("larger")} {
    font-size: $font-size + 5;
  }

  @media #{md("largest")} {
    font-size: $font-size + 6;
  }
}

body {
  padding: 0 $line-height * 1rem;
  font-family: $font-serif;
  font-size: 1em;
  line-height: $line-height;
  color: $font-color;
  background: #fff;

  // Media queries
  @media #{md("medium")} {
    // padding: 0 $line-height * 1rem;
  }
}

figure {
  margin: 0;
  padding: 0;
}

// img {
// 	width: 100%;
// 	height: auto;
// }

.js-lazy {
  opacity: 0;
}

.is-loaded {
  opacity: 1;
  transition: opacity 1s $out-expo;
}

/*------------------------------------*\
    Back top
\*------------------------------------*/

.back-top {
  position: fixed;
  right: $line-height * 1rem;
  bottom: $line-height * 1rem;
  display: block;

  opacity: 0;
  transform: scale(0);
  transition: all 0.4s $out-expo;

  &:before {
    display: none;
  }

  &.is-visible {
    opacity: 1;
    transform: scale(1);
  }
}

/*------------------------------------*\
		Cookies
\*------------------------------------*/
// .cookies {
// 	z-index: z("modal");
// 	position: fixed;
// 	bottom: 0;
// 	left: 0;
// 	display: none;
// 	width: 100%;
// 	text-align: center;

// 	background-color: #fff;
// 	visibility: hidden;

// 	&.is-visible {
// 		display: block;
// 		visibility: visible;
// 	}
// }

// .cookies-btn {
// 	display: inline-block;
// 	margin-left: 0.5em;
// 	padding: 0.2em 0.5em;
// 	color: #fff;
// }

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

;@import "sass-embedded-legacy-load-done:6";