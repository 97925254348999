/*
Typographie
===========

Règles de base pour tout le site (h1, h2, blockquote, etc).

-->    http://type-scale.com/    <--

Pratique pour générer des rythmes typographiques rapidement
*/

body {
  line-height: $line-height;
}

p,
ul,
ol,
figure,
blockquote {
  margin-top: $line-height * 1em;
  margin-bottom: $line-height * 1em;
}

h1,
h2,
h3,
h4,
.Titre,
.Sous-titre {
  margin: #{$line-height * 1em} 0 0.5em;
  font-family: $font-sans;
  font-weight: 900;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

h1 {
  margin-top: 0;
  font-size: 3.157em;
}

h2 {
  font-size: 2.369em;
}

h3 {
  font-size: 1.777em;
}

h4 {
  font-size: 1.333em;
}

small {
  font-size: 0.75em;
}

sup {
  font-size: 0.75em;
  vertical-align: super;
}

a {
  // z-index: z("default");
  color: inherit;
  text-decoration: none;
  transition: color 0.6s $out-expo;

  // border-bottom: 2px solid rgba($orange, 0.75);

  // &:before {
  // 	content: "";
  // 	z-index: z("default", -2);
  // 	position: absolute;
  // 	top: 0;
  // 	left: 0;
  // 	display: block;
  // 	width: 100%;
  // 	height: 100%;
  // 	background: linear-gradient(to bottom, transparent $line-height * 1em - 0.2em, $orange $line-height * 1em - 0.2em, $orange $line-height * 1em - 0.1em, transparent $line-height * 1em - 0.1em, transparent) repeat-y 0 0;
  // 	background-size: 100% $line-height * 1em;
  // 	// height: 0.1em;
  // 	// margin-top: -0.55em;
  // 	// background: $orange;
  // 	// border-radius: 0.15em;

  // 	opacity: 0.75;
  // 	// transform: translateZ(0);
  // 	transition: opacity 0.4s $out-expo;
  // }

  &:hover {
    // border-color: $orange;

    // &:before {
    // 	opacity: 1;
    // }
  }
}

a[href*='http']:not([href*='alloayiti']) {
  &:hover {
    &:after {
      transform: scale(-1, 1) translate(-0.25em, -0.25em);
    }
  }

  &:after {
    content: '↖';
    display: inline-block;
    margin-left: 0.25em;
    font-family: $font-sans;
    font-size: 0.75em;
    transform: scale(-1, 1);
    will-change: transform;
    // color: darken(orange, 10%);
    transition: all 0.2s $out-expo;
  }
}

pre {
  font-family:
    Fira Mono,
    monospace;
  font-size: 0.875em;
}

blockquote {
  // padding-right: $line-height * 1em;
  // padding-left: $line-height * 1em;
  margin: 0;
  padding: 0;

  // &:before {
  // 	content: "";
  // 	position: absolute;
  // 	top: 0;
  // 	left: 0;
  // 	width: $line-height * 0.25em;
  // 	height: 100%;
  // 	background: url("#{$svg-dir}bar--vertical.svg") no-repeat 50% 50%;
  // 	background-size: 100% 100%;
  // 	opacity: 0.1;
  // }

  > *:last-child:after,
  > *:first-child:before {
    position: absolute;
    display: inline-block;
    font-size: 6em;
    color: $orange;
    // opacity: 0.5;
  }

  > *:first-child:before {
    content: '“';
    top: -0.5em;
    // left: 0.23em;
    margin-left: -0.5em;
  }

  > *:last-child:after {
    content: '”';
    // right: 0.23em;
    // right: 0;
    bottom: -1em;
    margin-left: 0.1em;
  }
}

.Titre,
.Sous-titre {
  display: block;
}

.Titre {
  font-size: 1.777em;
}

.Sous-titre {
  font-size: 1.1em;
}

.Logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  a {
    padding: $line-height * 0.5em;
    border: 0 !important;

    &[href*='http']:not([href*='alloayiti']):after {
      display: none;
    }
  }
}

/* stylelint-disable-next-line selector-type-no-unknown */
twitterwidget,
.twitter-tweet {
  margin: $line-height * 1em auto !important;
}

;@import "sass-embedded-legacy-load-done:7";