/*------------------------------------*\
		Grid system (Bootstrap)
\*------------------------------------*/

.wrapper {
  width: $container-width;
  max-width: $container-max-width;
  margin-right: auto;
  margin-left: auto;
}

.wrapper--large {
  max-width: $container-max-width * 2;
}

.wrapper--no-maxw {
  max-width: none;
}

.row {
  @include clearfix;

  /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector, no-duplicate-selectors */
  & {
    margin-right: -$grid-gutter-smaller * 0.5;
    margin-left: -$grid-gutter-smaller * 0.5;
  }

  // Media queries
  @media #{md("small")} {
    margin-right: -$grid-gutter-small * 0.5;
    margin-left: -$grid-gutter-small * 0.5;
  }

  @media #{md("medium")} {
    margin-right: -$grid-gutter-medium * 0.5;
    margin-left: -$grid-gutter-medium * 0.5;
  }
}

.container,
[class*='col-xs-'],
[class*='col-s-'],
[class*='col-m-'],
[class*='col-n-'],
[class*='col-l-'],
[class*='col-xl-'] {
  min-height: 1px;
  padding-right: $grid-gutter-smaller * 0.5;
  padding-left: $grid-gutter-smaller * 0.5;

  // Media queries
  @media #{md("small")} {
    padding-right: $grid-gutter-small * 0.5;
    padding-left: $grid-gutter-small * 0.5;
  }

  @media #{md("medium")} {
    padding-right: $grid-gutter-medium * 0.5;
    padding-left: $grid-gutter-medium * 0.5;
  }
}

.container--large,
.row--large [class*='col-xs-'],
.row--large [class*='col-s-'],
.row--large [class*='col-m-'],
.row--large [class*='col-n-'],
.row--large [class*='col-l-'],
.row--large [class*='col-xl-'] {
  padding-right: $grid-gutter-smaller;
  padding-left: $grid-gutter-smaller;

  // Media queries
  @media #{md("small")} {
    padding-right: $grid-gutter-small;
    padding-left: $grid-gutter-small;
  }

  @media #{md("medium")} {
    padding-right: $grid-gutter-medium;
    padding-left: $grid-gutter-medium;
  }
}

.row--center {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  [class*='col-xs-'],
  [class*='col-s-'],
  [class*='col-m-'],
  [class*='col-n-'],
  [class*='col-l-'],
  [class*='col-xl-'] {
    display: inline-block;
    float: none;
  }
}

[class*='col-xs-'],
[class*='col-s-'],
[class*='col-m-'],
[class*='col-n-'],
[class*='col-l-'],
[class*='col-xl-'] {
  float: left;

  &.right {
    float: right;
  }
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-0 {
  width: 0;
}

@media #{md("smaller")} {
  .col-s-12 {
    width: 100%;
  }

  .col-s-11 {
    width: 91.66666667%;
  }

  .col-s-10 {
    width: 83.33333333%;
  }

  .col-s-9 {
    width: 75%;
  }

  .col-s-8 {
    width: 66.66666667%;
  }

  .col-s-7 {
    width: 58.33333333%;
  }

  .col-s-6 {
    width: 50%;
  }

  .col-s-5 {
    width: 41.66666667%;
  }

  .col-s-4 {
    width: 33.33333333%;
  }

  .col-s-3 {
    width: 25%;
  }

  .col-s-2 {
    width: 16.66666667%;
  }

  .col-s-1 {
    width: 8.33333333%;
  }

  .col-s-0 {
    width: 0;
  }

  .push-top-s-1 {
    margin-top: 8.33333%;
  }

  .push-left-s-1 {
    margin-left: 8.33333%;
  }

  .clear-s-left {
    clear: left;
  }

  .clear-s-right {
    clear: right;
  }

  .clear-s-both {
    clear: both;
  }
}

@media #{md("small")} {
  .col-m-12 {
    width: 100%;
  }

  .col-m-11 {
    width: 91.66666667%;
  }

  .col-m-10 {
    width: 83.33333333%;
  }

  .col-m-9 {
    width: 75%;
  }

  .col-m-8 {
    width: 66.66666667%;
  }

  .col-m-7 {
    width: 58.33333333%;
  }

  .col-m-6 {
    width: 50%;
  }

  .col-m-5 {
    width: 41.66666667%;
  }

  .col-m-4 {
    width: 33.33333333%;
  }

  .col-m-3 {
    width: 25%;
  }

  .col-m-2 {
    width: 16.66666667%;
  }

  .col-m-1 {
    width: 8.33333333%;
  }

  .col-m-0 {
    width: 0;
  }

  .push-top-m-1 {
    margin-top: 8.33333%;
  }

  .push-left-m-1 {
    margin-left: 8.33333%;
  }

  .clear-m-left {
    clear: left;
  }

  .clear-m-right {
    clear: right;
  }

  .clear-m-both {
    clear: both;
  }
}

@media #{md("medium")} {
  .col-n-12 {
    width: 100%;
  }

  .col-n-11 {
    width: 91.66666667%;
  }

  .col-n-10 {
    width: 83.33333333%;
  }

  .col-n-9 {
    width: 75%;
  }

  .col-n-8 {
    width: 66.66666667%;
  }

  .col-n-7 {
    width: 58.33333333%;
  }

  .col-n-6 {
    width: 50%;
  }

  .col-n-5 {
    width: 41.66666667%;
  }

  .col-n-4 {
    width: 33.33333333%;
  }

  .col-n-3 {
    width: 25%;
  }

  .col-n-2 {
    width: 16.66666667%;
  }

  .col-n-1 {
    width: 8.33333333%;
  }

  .col-n-0 {
    width: 0;
  }
}

@media #{md("large")} {
  .col-l-12 {
    width: 100%;
  }

  .col-l-11 {
    width: 91.66666667%;
  }

  .col-l-10 {
    width: 83.33333333%;
  }

  .col-l-9 {
    width: 75%;
  }

  .col-l-8 {
    width: 66.66666667%;
  }

  .col-l-7 {
    width: 58.33333333%;
  }

  .col-l-6 {
    width: 50%;
  }

  .col-l-5 {
    width: 41.66666667%;
  }

  .col-l-4 {
    width: 33.33333333%;
  }

  .col-l-3 {
    width: 25%;
  }

  .col-l-2 {
    width: 16.66666667%;
  }

  .col-l-1 {
    width: 8.33333333%;
  }

  .col-l-0 {
    width: 0;
  }
}

@media #{md("larger")} {
  .col-xl-12 {
    width: 100%;
  }

  .col-xl-11 {
    width: 91.66666667%;
  }

  .col-xl-10 {
    width: 83.33333333%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-8 {
    width: 66.66666667%;
  }

  .col-xl-7 {
    width: 58.33333333%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-5 {
    width: 41.66666667%;
  }

  .col-xl-4 {
    width: 33.33333333%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-2 {
    width: 16.66666667%;
  }

  .col-xl-1 {
    width: 8.33333333%;
  }

  .col-xl-0 {
    width: 0;
  }
}

;@import "sass-embedded-legacy-load-done:10";