@use 'sass:color';

/*------------------------------------*\
		Panel
\*------------------------------------*/

.panel {
  z-index: z('panel');
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100%;
  height: 100%;
}

.panel__controls {
  position: absolute;
  top: $line-height * 1.2em;
  right: $line-height * 1.5em;
}

.panel__close {
  z-index: z('default');
  display: block;
  font-family: $font-sans;
  font-size: 0.75em;
  font-weight: 900;
  line-height: $line-height;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  border-bottom: 2px solid $orange-light;
  transition: border 0.6s $out-expo;

  &:hover {
    border-color: $orange;
  }
}

.panel__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $orange-light;
  opacity: 0.95;
  // opacity: 0;
}

.panel__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 24em;
  height: 100%;
  padding: $line-height * 1.2em $line-height * 1.5em $line-height * 2em;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0.875em;
  background: color.adjust($grey-lightest, $lightness: -0.75%);
  border-left: 1px solid color.adjust($grey-lightest, $lightness: -5%);

  &::before {
    $size: 30px;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: $size;
    height: $size;
    margin: $size * -0.5 0 0 $size * -0.5;
    background-image: url('#{$svg-dir}loader.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: $size $size;

    opacity: 0;
    transition: opacity 1s $out-expo;
    animation: rotate 1s $in-out-sine infinite;

    .is-loading & {
      opacity: 1;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(720deg);
  }
}

;@import "sass-embedded-legacy-load-done:95";